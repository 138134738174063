import React from "react";
import "../style/pricing.css";
const Pricing = (props) => {
  return (
    <>
      {/* <div className='headline'>Pricing</div> */}
      <div className="pricing">
        <div className="header">
          <h1>{props.head}</h1>
        </div>
        <div className="price">
          <h2>{props.price}</h2>
        </div>
        <div className="content" style={{textShadow: '2px 2px 5px rgb(144, 0, 211)'}}>
      {props.pages} Pages Website (Full Responsive Design)
          {/* <br></br>
          <br></br>
          {props.businessEmails} */}
          <br/>
          <br></br>
          Unlimited Images & Videos
          <br/>
          <br></br>
          {props.seo}
          <br/>
          <br></br>
          {props.logo} Logo Design
          <br/>
          <br></br>
          Google Map/ Business Listing
          <br/>
          <br></br>
          100% Responsive Website
          <br/>
          <br></br>
         {props.adminPanel}
          <br/>
          <br></br>
          CPanel Access
          <br/>
          <br></br>
          {props.payment}
          <br/>
          <br></br>
          Social Media Pages Integration
          <br/>
          <br></br>
          Call and WhatsApp Button Integration
          <br/>
          <br></br>
          Inquiry Form With Email Notification
          <br/>
          <br></br>
          {props.chatSoftware}
          <br/>
          <br></br>
          24/7 Phone/Email/Chat Support
          <br/>
          <br></br>{props.year} Free Maintenance
        </div>
      </div>
    </>
  );
};

export default Pricing;

import React from "react";
import Pricing from "../components/Pricing";
import "../style/pricing_page.css";
import BackToTopButton from "./BackToTopButton";
import { Link } from "react-router-dom";
import WhatsAppLink from "./WhatsAppLink";
const Pricing_page = () => {
  return (
    <div>
      <BackToTopButton />
      <WhatsAppLink />
      <div className="price-portion">
        <div className="heads">
          <div className="headline">Pricing</div>
          <h1>Website Design and Development</h1>
          {/* <p>
            Website design and development services encompass the creation and
            construction of websites. This involves planning, designing, coding,
            and optimizing websites for functionality, user experience, and
            aesthetics.
          </p> */}
        </div>
        <div>
          {/* <div className="headline">Plans</div> */}
          <div className="price-cntr">
            <Pricing
              head="Basic"
              price="Rs.4999"
              pages="5"
              seo="SEO Ready Website With 25 to 40 Keywords"
              logo="No"
              adminPanel="Admin Panel Available (Full Access)"
              payment="No Payment Gateway Integration"
              chatSoftware="No Chat Software"
              year="3 Month"
            />
            <Pricing
              head="Standard"
              price="Rs.9999"
              pages="10 - 20"
              seo="SEO Ready Website With 50 to 100 Keywords"
              logo="No"
              adminPanel="Admin Panel Available (Full Access)"
              payment="Payment Gateway Integration"
              chatSoftware="No Chat Software"
              year="3 Month"
            />
            <Pricing
              head="Premium"
              price="Customize"
              pages="Unlimited"
              seo="SEO Ready Website With 100+ Keywords"
              logo=""
              adminPanel="Customize Designed Admin Panel(Full Access)"
              payment="Payment Gateway Integration"
              chatSoftware="Chat Software"
              year="6 Month"
            />
          </div>
        </div>
        <div className="price_btn">
          <Link to="/websiteDesign">
            <button>SERVICES</button>
          </Link>
          <Link to="/contact">
            <button>Contact Us</button>
          </Link>
        </div>
        {/* <div className="hrline"></div> */}
      </div>

      {/* <div className="price-portion">
        <div className="heads">
          <h1>Social Media Marketing</h1>
          <p>
            In today's dynamic business landscape, social media marketing is not
            just an option; it's a strategic necessity. It's the bridge that
            connects your brand with a world of opportunities. With the right
            social media strategy, your business can engage with your target
            audience on platforms they already use daily.
          </p>
        </div>
        <div>
          <div className="headline">Plans</div>
          <div className="price-cntr">
            <Pricing head="Basic" price="Rs.5000" />
            <Pricing head="Standard" price="Rs.7000" />
            <Pricing head="Premium" price="Rs.10000" />
          </div>
        </div>
        <div className="price_btn">
          <Link to="/social_media_marketting">
            <button>Service</button>
          </Link>
          <Link to="/contact">
            <button>Contact Us</button>
          </Link>
        </div>
        <div className="hrline"></div>
      </div>

      <div className="price-portion">
        <div className="heads">
          <h1>Search Engine Optimization</h1>
          <p>
            In today's digital landscape, having a strong online presence is
            non-negotiable, and SEO (Search Engine Optimization) is the linchpin
            to achieving it. SEO isn't just about ranking higher on search
            engines; it's about ensuring that your business is discoverable by
            the right people at the right time.{" "}
          </p>
        </div>
        <div>
          <div className="headline">Plans</div>
          <div className="price-cntr">
            <Pricing head="Basic" price="Rs.5000" />
            <Pricing head="Standard" price="Rs.7000" />
            <Pricing head="Premium" price="Rs.10000" />
          </div>
        </div>
        <div className="price_btn">
          <Link to="/seo">
            <button>Service</button>
          </Link>
          <Link to="/contact">
            <button>Contact Us</button>
          </Link>
        </div>
        <div className="hrline"></div>
      </div>

      <div className="price-portion">
        <div className="heads">
          <h1>Mobile Application</h1>
          <p>
            In today's mobile-centric world, a well-crafted mobile application
            is your gateway to unprecedented growth and engagement. A mobile app
            isn't just an extension of your brand; it's a powerful tool that
            connects you directly to your customers, wherever they are. It's the
            key to unlocking a seamless and personalized user experience,
            fostering brand loyalty, and expanding your reach.{" "}
          </p>
        </div>
        <div>
          <div className="headline">Plans</div>
          <div className="price-cntr">
            <Pricing head="Basic" price="Rs.5000" />
            <Pricing head="Standard" price="Rs.7000" />
            <Pricing head="Premium" price="Rs.10000" />
          </div>
        </div>
        <div className="price_btn">
          <Link to="/mobile_app">
            <button>Service</button>
          </Link>
          <Link to="/contact">
            <button>Contact Us</button>
          </Link>
        </div>
        <div className="hrline"></div>
      </div>

      <div className="price-portion">
        <div className="heads">
          <h1>Software Development</h1>
          <p>
            In today's fast-paced digital landscape, the role of custom software
            development cannot be overstated. It serves as the cornerstone of
            digital transformation, enabling businesses to adapt, evolve, and
            thrive in an ever-changing market. Custom software isn't just about
            streamlining operations; it's about empowering your business with
            the tools to innovate and excel. Whether you're looking to create a
            web application, mobile app, or a comprehensive software solution,
            our dedicated team of experts is committed to bringing your vision
            to life.
          </p>
        </div>
        <div>
          <div className="headline">Plans</div>
          <div className="price-cntr">
            <Pricing head="Basic" price="Rs.5000" />
            <Pricing head="Standard" price="Rs.7000" />
            <Pricing head="Premium" price="Rs.10000" />
          </div>
        </div>
      </div>
      <div className="price_btn">
        <Link to="/soft_dev">
          <button>Service</button>
        </Link>
        <Link to="/contact">
          <button>Contact Us</button>
        </Link>
      </div>
      <div className="hrline"></div> */}
    </div>
  );
};

export default Pricing_page;

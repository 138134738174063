import React from "react";
// import "../style/about.css";
import styles from "../style/AboutHome.module.css";
// import corporate from "../images/corporate.png";
import corporate from "../images/shiny-night-city.jpg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div className={styles.about}>
        <div className={styles.pic}>
          <img src={corporate} alt="" />
        </div>
        <div className={styles.aboutcontent}>
          <h1>About Flerid</h1>
          <h5>
          Flerid is a dynamic web and app development, digital marketing company. Our savvy team offers a strategic approach, leveraging branding and marketing, and employs research-driven methods to propel your business towards unprecedented success and goal attainment.
         <Link to="/about"> <button className="btn_more">KNOW MORE</button></Link>
          </h5>
         
        </div>
      </div>
    </>
  );
};

export default About;

import React from "react";
import ServiceCard from "./ServiceCard";
import styles from "./Services.module.css";
import Image from "../images/social_media_1.jpg";
import Graph from "./img/graph.png";
import Asthetic from "./img/asthetic.png";
import Faster from "./img/faster.png";
import Content from "./img/content.png";
import Optimized from "./img/optimized.png";
import Responsive from "./img/responsive.png";
import NeedWebsite from "../images/social_media_2.jpg";
import BackToTopButton from "./BackToTopButton";
import WhatsAppLink from "./WhatsAppLink";

const Services1 = () => {
  return (
    <>
      <div className={styles.container}>
        <BackToTopButton/>
        <WhatsAppLink/>
        <h1>Social Media and Marketing</h1>
        <div className={styles.design}>
          <div className={styles.leftDesign}>
          In today's dynamic business landscape, social media marketing is not merely an option but a strategic necessity. It serves as the bridge connecting your brand to a world of opportunities. A well-crafted social media strategy enables your business to actively engage with your target audience on their daily platforms. From building brand awareness to fostering customer loyalty, and driving website traffic to boosting sales, social media marketing offers a versatile, measurable return on investment. Our dedicated team tailors bespoke strategies, ensuring your brand stands out in the digital space, fostering sustainable growth and success.
          </div>
          <div className={styles.rightDesign}>
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.banner}>
        <h1>CONNECTING YOUR BRAND TO THE WORLD, ONE CLICK AT A TIME</h1>
        <div className={styles.Cards}>
          <ServiceCard icon={Graph} heading={"Uploading interactive posts"} />
          <ServiceCard
            icon={Asthetic}
            heading={"Increasing Followers"}
          />
          <ServiceCard icon={Content} heading={"Increasing Brand Name"} />
          <ServiceCard icon={Responsive} heading={"Getting influencers"} />
          <ServiceCard
            icon={Optimized}
            heading={"Positive brand reputation"}
          />
          <ServiceCard icon={Faster} heading={"Positioning your brand"} />
        </div>
      </div>

      <div className={styles.designSecond}>
        <div className={styles.leftDesign}>
          <h2>WHY DO YOU NEED SOCIAL MEDIA AND MARKETTING?</h2>social media is essential for marketing. Platforms are powerful communication channels, fostering brand loyalty, driving traffic, and increasing revenue. A strong social media presence is crucial for staying relevant and thriving in the competitive digital landscape.
        </div>
        <div className={styles.rightDesign}>
          <img src={NeedWebsite} alt="" />
        </div>
      </div>
      <div className={styles.chooseUs}>
        <h1>"Let's boost your brand with social media and marketing!"</h1>
      </div>
    </>
  );
};

export default Services1;

// Neon blue (#2272FF) black (#000)

import React, { useState } from "react";
import "../style/Form.css";
import Icons from "../components/Icons";
import BackToTopButton from "./BackToTopButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact_form = () => {
  const [userData, setUserData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  let name, value;
  let res;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    console.log("Submitting data");
    event.preventDefault();
    const { name, number, email, message } = userData;

    if (name && number && email && message) {
      res = await fetch(
        "https://flerid-19b89-default-rtdb.firebaseio.com/userDataRecords.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            number,
            email,
            message,
          }),
        }
      );
      if (res) {
        setUserData({
          name: "",
          number: "",
          email: "",
          message: "",
        });
        toast.success(
          "Successfully sent your message; Thank you so much for contact us!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else {
        toast.error("Sorry, something went wrong; Call us at 6003351943", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error("Please fill all the details or call us at 6003351943", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <BackToTopButton />
      <form>
        

        <div className="form-group position-relative form_design ">
          <input
            type="text"
            id="name"
            name="name"
            className="form-control form-control-lg thick"
            placeholder="Name"
            value={userData.name}
            onChange={postUserData}
          />
        </div>

        <div className="form-group position-relative form_design ">
          <input
            type="number"
            id="number"
            name="number"
            className="form-control form-control-lg thick"
            placeholder="Phone"
            value={userData.number}
            onChange={postUserData}
          />
        </div>

        <div className="form-group position-relative form_design ">
          <input
            type="email"
            id="email"
            name="email"
            className="form-control form-control-lg thick"
            placeholder="E-mail"
            value={userData.email}
            onChange={postUserData}
          />
        </div>

        <div className="form-group message form_design" >
          <textarea
            id="message"
            name="message"
            className="form-control form-control-lg"
            rows="7"
            placeholder="Message"
            value={userData.message}
            onChange={postUserData}
          ></textarea>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            tabIndex="-1"
            onClick={submitData}
          >
            Send Message
          </button>
        </div>
      </form>
      <Icons />
      <ToastContainer
        toastStyle={{
          position: "absolute",
          top: "-5",
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          color: "white",
        }}
      />
    </>
  );
};

export default Contact_form;

// import React, { useState } from "react";
// import styles from "./styles/Menu.module.css";
// import DownArrow from "../assets/down_arrow_icon.svg"
// import Logo from "../assets/logo.png"
// import { Link } from 'react-router-dom';

// const Menu = () => {

//   return (
//     <>
//       <nav className="navbar navbar-expand-lg navbar-dark  px-4 pt-3 pb-3" style={{backgroundColor:'black'}}>
//         <div className={styles.navbar_brand}>
//           <img src={Logo}/>
//           <h3>FLERID TECHNOLOGIES</h3>
//         </div>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-toggle="collapse"
//           data-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav ml-auto">
//             <li className="nav-item active">
//               <Link to="/home" className={styles.nav_link}>
//                 Home
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link to='/about' className={styles.nav_link}>
//                 About Us
//               </Link>
//             </li>
//             {/* <li className="nav-item">
//               <Link to='/pricing' className={styles.nav_link}>
//                 Link
//               </Link>
//             </li> */}
//             <li className="nav-item">
//               <Link to='/pricing' className={styles.nav_link}>
//                 Pricing
//               </Link>
//             </li>
//             <li className="nav-item ">
//               <div className={styles.dropdown}>
//                 <div className={styles.dropbtn}>Services <img src={DownArrow}/> </div>
//                 <div className={styles.dropdown_content}>
//                   <Link to="/websiteDesign">Website Design & Development</Link>
//                   <Link to="/social_media_marketting">Social Media Marketing</Link>
//                   <Link to="/seo">SEO</Link>
//                   <Link to="/mobile_app">Mobile App Development</Link>
//                   <Link to="/soft_dev">Software Development</Link>
//                 </div>
//               </div>
//             </li>
//             <li className="nav-item ">
//               <div className={styles.dropdown}>
//                 <div className={styles.dropbtn}>Blogs <img src={DownArrow}/> </div>
//                 <div className={styles.dropdown_content}>
//                   <Link to="/blog1">Why Web Development and Digital Marketing are important for a Business?
//                   </Link>
//                   <Link to="/blog2">Why Fame and Social Media Popularity Matter in Business?</Link>
//                 </div>
//               </div>
//             </li>
//             <li className="nav-item">
//               <Link to='/contact' className={styles.nav_link}>
//                 Contact Us
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </nav>
//     </>
//   );
// };

// export default Menu;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import Logo from "../assets/logo.png";
// import DownArrow from "../assets/down_arrow_icon.svg";
// import styles from "./styles/Menu.module.css";

// const Menu = () => {
//   const [expanded, setExpanded] = useState(false);

//   const closeNav = () => setExpanded(false);

//   return (
//     <Navbar
//       expand="lg"
//       variant="dark"
//       className="px-4 pt-3 pb-3"
//       style={{ backgroundColor: "black" }}
//       expanded={expanded}
//     >
//       <Navbar.Brand className={styles.navbar_brand}>
//         <img src={Logo} alt="Logo" />
//         <h3>FLERID TECHNOLOGIES</h3>
//       </Navbar.Brand>
//       <Navbar.Toggle
//         aria-controls="responsive-navbar-nav"
//         onClick={() => setExpanded(!expanded)}
//       />

//       <Navbar.Collapse id="responsive-navbar-nav">
//         <Nav className="ml-auto" onClick={closeNav}>
//         <ul className="navbar-nav ml-auto">
//         <li className="nav-item">
//           <Link to="/home" className={`${styles.nav_link} nav-item`}>
//             Home
//           </Link>
//           </li>
//           <Link to="/about" className={`${styles.nav_link} nav-item`}>
//             About Us
//           </Link>
//           <Link to="/pricing" className={`${styles.nav_link} nav-item`}>
//             Pricing
//           </Link>
//           <NavDropdown
//             title="Services"
//             id="basic-nav-dropdown"
//             className="nav-item"
//           >
//             <Link
//               to="/websiteDesign"
//               className={`${styles.nav_link} dropdown-item`}
//             >
//               Website Design & Development
//             </Link>
//             <Link
//               to="/social_media_marketing"
//               className={`${styles.nav_link} dropdown-item`}
//             >
//               Social Media Marketing
//             </Link>
//             <Link to="/seo" className={`${styles.nav_link} dropdown-item`}>
//               SEO
//             </Link>
//             <Link
//               to="/mobile_app"
//               className={`${styles.nav_link} dropdown-item`}
//             >
//               Mobile App Development
//             </Link>
//             <Link to="/soft_dev" className={`${styles.nav_link} dropdown-item`}>
//               Software Development
//             </Link>
//           </NavDropdown>

//           <NavDropdown
//             title="Blogs"
//             id="basic-nav-dropdown"
//             className="nav-item"
//           >
//             <Link to="/blog1" className={`${styles.nav_link} dropdown-item`}>
//               Why Web Development and Digital Marketing are important for a
//               Business?
//             </Link>
//             <Link to="/blog2" className={`${styles.nav_link} dropdown-item`}>
//               Why Fame and Social Media Popularity Matter in Business?
//             </Link>
//           </NavDropdown>

//           <Link to="/contact" className={`${styles.nav_link} nav-item`}>
//             Contact Us
//           </Link>
//           {/* ... other Link components ... */}
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default Menu;










import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/logo.png";
import DownArrow from "../assets/down_arrow_icon.svg";
import styles from "./styles/Menu.module.css";

const Menu = () => {
  const [expanded, setExpanded] = useState(false);

  const closeNav = () => setExpanded(false);

  const stopPropagation = (e) => {
    // This function stops the event propagation, preventing the navbar from closing
    e.stopPropagation();
  };

  return (
    <Navbar
      expand="lg"
      variant="dark"
      className={`px-4 pt-3 pb-3 ${styles.customNavbar}`}
      expanded={expanded}
    >
      <div className={styles.navbar_brand}>
        <img src={Logo} alt="Logo" />
        <h3>FLERID TECHNOLOGIES</h3>
      </div>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(!expanded)}
        style={{ border:'1px solid white' }}
      />

      <Navbar.Collapse id="responsive-navbar-nav">
        <ul
          className={`navbar-nav ml-auto ${styles.customNav}`}
          onClick={closeNav}
        >
          <li className="nav-item">
            <Link
              to="/home"
              className={`${styles.nav_link} ${styles.customNavItem}`}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about"
              className={`${styles.nav_link} ${styles.customNavItem}`}
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/pricing"
              className={`${styles.nav_link} ${styles.customNavItem}`}
            >
              Pricing
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/portfolio"
              className={`${styles.nav_link} ${styles.customNavItem}`}
            >
              Portfolio
            </Link>
          </li> */}
          <li className="nav_link">
            <div className={styles.dropdown}>
              <div className={styles.dropbtn} onClick={stopPropagation}>
                Services <img src={DownArrow} alt="Down Arrow" />
              </div>
              <div className={styles.dropdown_content}>
                <Link to="/websiteDesign">Website Design & Development</Link>
                <Link to="/social_media_marketing">Social Media Marketing</Link>
                <Link to="/seo">SEO</Link>
                <Link to="/mobile_app">Mobile App Development</Link>
                <Link to="/soft_dev">Software Development</Link>
              </div>
            </div>
          </li>

          <li className={`nav-item ${styles.customNavItem}`}>
            <div className={styles.dropdown}>
              <div className={styles.dropbtn} onClick={stopPropagation}>
                Blogs <img src={DownArrow} alt="Down Arrow" />
              </div>
              <div className={styles.dropdown_content}>
                <Link to="/blog1">
                  Why Web Development and Digital Marketing are important for a
                  Business?
                </Link>
                <Link to="/blog2">
                  Why Fame and Social Media Popularity Matter in Business?
                </Link>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link
              to="/contact"
              className={`${styles.nav_link} ${styles.customNavItem}`}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;







// import React, { useState } from "react";
// import { Link } from 'react-router-dom';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Logo from "../assets/logo.png";
// import DownArrow from "../assets/down_arrow_icon.svg";
// import styles from "./styles/Menu.module.css";

// const Menu = () => {
//   const [expanded, setExpanded] = useState(false);

//   const handleNavToggle = () => {
//     setExpanded(!expanded);
//   };

//   const closeNav = () => {
//     // Only close the nav if it's expanded
//     if (expanded) {
//       setExpanded(false);
//     }
//   };

//   return (
//     <Navbar expand="lg" variant="dark" className={`px-4 pt-3 pb-3 ${styles.customNavbar}`} expanded={expanded}>
//       <div className={styles.navbar_brand}>
//         <img src={Logo} alt="Logo" />
//         <h3>FLERID TECHNOLOGIES</h3>
//       </div>
//       <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleNavToggle} />

//       <Navbar.Collapse id="responsive-navbar-nav">
//         <ul className={`navbar-nav ml-auto ${styles.customNav}`} onClick={closeNav}>
//           <li className="nav-item">
//             <Link to="/home" className={`${styles.nav_link} ${styles.customNavItem}`}>Home</Link>
//           </li>
//           <li className="nav-item">
//             <Link to='/about' className={`${styles.nav_link} ${styles.customNavItem}`}>About Us</Link>
//           </li>
//           <li className="nav-item">
//             <Link to='/pricing' className={`${styles.nav_link} ${styles.customNavItem}`}>Pricing</Link>
//           </li>

//           <li className={`nav-item ${styles.customNavItem}`}>
//             <div className={styles.dropdown}>
//               <div className={styles.dropbtn}>Services <img src={DownArrow} alt="Down Arrow" /> </div>
//               <div className={styles.dropdown_content}>
//                 <Link to="/websiteDesign">Website Design & Development</Link>
//                 <Link to="/social_media_marketing">Social Media Marketing</Link>
//                 <Link to="/seo">SEO</Link>
//                 <Link to="/mobile_app">Mobile App Development</Link>
//                 <Link to="/soft_dev">Software Development</Link>
//               </div>
//             </div>
//           </li>

//           <li className={`nav-item ${styles.customNavItem}`}>
//             <div className={styles.dropdown}>
//               <div className={styles.dropbtn}>Blogs <img src={DownArrow} alt="Down Arrow" /> </div>
//               <div className={styles.dropdown_content}>
//                 <Link to="/blog1">Why Web Development and Digital Marketing are important for a Business?</Link>
//                 <Link to="/blog2">Why Fame and Social Media Popularity Matter in Business?</Link>
//               </div>
//             </div>
//           </li>

//           <li className="nav-item">
//             <Link to='/contact' className={`${styles.nav_link} ${styles.customNavItem}`}>Contact Us</Link>
//           </li>
//         </ul>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default Menu;

import React from "react";
import ServiceCard from "./ServiceCard";
import styles from "./Services.module.css";
import NeedWebsite from "../images/mobile_App_1.jpg";
import Graph from "./img/graph.png";
import Asthetic from "./img/asthetic.png";
import Faster from "./img/faster.png";
import Content from "./img/content.png";
import Optimized from "./img/optimized.png";
import Responsive from "./img/responsive.png";
import Image from "../images/mobile_2.jpg";
import BackToTopButton from "./BackToTopButton";
import WhatsAppLink from "./WhatsAppLink";

const Services3 = () => {
  return (
    <>
      <div className={styles.container}>
        <BackToTopButton />
        <WhatsAppLink />
        <h1>Mobile Application Development</h1>
        <div className={styles.design}>
          <div className={styles.leftDesign}>
            Mobile app development is the process of creating software
            applications specifically designed for mobile devices like
            smartphones and tablets. It involves designing, coding, testing, and
            deploying applications across various platforms, such as iOS and
            Android. Developers use programming languages like Java, Swift, or
            Kotlin to build functional and user-friendly apps that cater to
            specific needs or entertainment. This dynamic field continuously
            evolves, adapting to technological advancements and user
            expectations for seamless and innovative mobile experiences
          </div>
          <div className={styles.rightDesign}>
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.banner}>
        <h1>EMPOWER YOUR BUSINESS WITH MOBILE APP INNOVATION!</h1>
        <div className={styles.Cards}>
          <ServiceCard
            icon={Graph}
            heading={"Be visible to customers at all times"}
          />
          <ServiceCard
            icon={Asthetic}
            heading={"Create a direct marketing channel"}
          />
          <ServiceCard
            icon={Content}
            heading={"Provide value to your customers"}
          />
          <ServiceCard icon={Responsive} heading={"Build brand recognition"} />
          <ServiceCard
            icon={Optimized}
            heading={"Improve customer engagement"}
          />
          <ServiceCard icon={Faster} heading={"Cultivate customer loyalty"} />
        </div>
      </div>

      <div className={styles.designSecond}>
        <div className={styles.leftDesign}>
          <h2>WHY DO YOU NEED A MOBILE APPLICATION?</h2>A mobile application
          streamlines accessibility, enhancing user engagement and convenience.
          It offers on-the-go access to services, personalized experiences, and
          efficient communication. With features like push notifications,
          offline functionality, and device integration, a mobile app optimizes
          user interaction, fostering brand loyalty and staying competitive in
          the digital landscape.
        </div>
        <div className={styles.rightDesign}>
          <img src={NeedWebsite} alt="" />
        </div>
      </div>
      <div className={styles.chooseUs}>
        <h1>"Let's Mobilize Your Success Together!"</h1>
      </div>
    </>
  );
};

export default Services3;

// Neon blue (#2272FF) black (#000)

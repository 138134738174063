import React from 'react'
// import aboutbg from '../images/aboutbg.jpg';
import '../style/blog.css';
// import { Link } from 'react-router-dom';
const Blog = (props) => {
    return (
        <div className='blog'>
            {/* <section className="info">
                <img src={aboutbg}/>
                    <h1>Learn HTML &mdash; <a href="https://codetheweb.blog/" target="_blank">Code The Web</a></h1>
            </section> */}
            <div className="num">{props.blog_number}</div>
            <a className="card">
                <div>
                    <h1>{props.headline}</h1>
                    <p>{props.content}</p>
                    <div className="date">{props.date}</div>
                    <div className="tags">
                        <div className="tag">{props.button_text}</div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default Blog
import React from 'react'
import '../style/cardstyle.css';
import { Link } from 'react-router-dom';
const Cards = (props) => {
    return (
        <div>
            {/* <div className='headline'>Our Services</div> */}
            <div className="ag-courses_item">
                <Link href="#services" className="ag-courses-item_link">
                    <div className="ag-courses-item_bg" style={props.style1}></div>

                    <div className="ag-courses-item_title">
                        {props.text1}
                    </div>

                    <div className="ag-courses-item_date-box">
                    {props.text2}
                        <span className="ag-courses-item_date" style={props.style2}>
                        {props.text3}
                        </span>
                    </div>
                </Link>
            </div>
            
        </div>
    )
}

export default Cards
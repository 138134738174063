import React, { useEffect } from 'react';
import '../style/hero.css';
import 'particles.js'; // Import Particle.js library

const Hero = () => {
  useEffect(() => {
    // Initialize Particle.js
    window.particlesJS('particles-js', {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 2,
            color: '#010100',
          },
          polygon: {
            nb_sides: 5,
          },
        },
        opacity: {
          value: 1,
          random: true,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: .7,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: 'repulse',
          },
        },
      },      
    });
  }, []);

  return (
    <div id="particles-js" className="particles-container">
      <section className="hero">
        <div className="content">
          <h2>WELCOME TO FLERID TECHNOLOGIES</h2>
          <p>
          We excel as your premier digital service provider, catalyzing business growth through innovative solutions. We shape your thoughts, fueling success digitally.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Hero;

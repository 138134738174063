import React, { useState, useEffect } from 'react';
import Whatsapp from "../images/whatsapp.gif";
import "../style/whatsapp.css";

const WhatsAppLink = () => {
  const phoneNumber = '+917086387830'; // Replace with the actual phone number
  const message = 'Hello, Chat with us!'; // Replace with your desired message

  // Encode the phone number and message for use in the URL
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const encodedMessage = encodeURIComponent(message);

  // Create the WhatsApp link
  const whatsappLink = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add scroll event listener to check if the button should be visible
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div>
      <div className={`whatsappIconContainer ${isVisible ? 'visible' : ''}`}>
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img
            src={Whatsapp}
            alt="GIF Image"
            style={{ width: '3.4rem', height: 'auto', borderRadius: '3.4rem' }}
          />
        </a>
      </div>
    </div>
  );
};

export default WhatsAppLink;

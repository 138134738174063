import React from "react";
import "../style/preblog.css";
import company1 from "../components/img/company1.jpg";
import company2 from "../components/img/company2.jpeg";
import company3 from "../components/img/company3.png";
const Preblog = () => {
  return (
    <div>
      <div className="mainpreblog">
      <div className="part1" style={{color:'white'}}>
          Get Your Website Developed And Ranked In Google Searches Call Us @
          6003351943
        </div>
        <div className="part2">
          Why To Choose Flerid Technologies?
          <p>
            Flerid Technologies Is the India’s Most Fastest Growing Website
            Development Company With A Complete Services Of Website Development
            , Android & iOS Application Development, SEO Optimization And
            Digital Marketing Services Across the Country With A Clients Base of
            5+ Happy Customers. We Have Provided Services To Top Startups Who
            Started their Business With Flerid as Complete Digital Marketing
            Partner. Flerid Has Been Established On 2023 With A Dedicated Team
            of Professional who Has A complete experience on Website Development
            Languages, Android And iOS App Development, We Completely Believe
            That our Customers Need Satisfiaction On Our Services , So We Always
            Try To Keep you Fully Satisfied And provide Transparency Among
            Services. Any Business , Individual Can Relay On us For our services
            And We Will do the Rest To Give you The Best services Among the
            Industry. We Do What We Commit and we Commit That The Customer
            satisfaction Is Our First Priority. Thanks For Choosing Flerid
            Technologies. Start Your Business Online With Us Today.
          </p>
        </div>
        <div className="part3">
          Confused How To Start?
          <p>
            Don't worry, we've got you covered. Whether you're launching a
            business, embarking on a project, or navigating a new venture, our
            expert guidance and tailored solutions will steer you in the right
            direction. Say goodbye to confusion and hello to clarity as we help
            you take those crucial first steps toward success.
          </p>
        </div>
        <div className="part4main">
          <div className="company_heading">Companies We Have Worked With</div>

          <div className="part4">
            <div className="company_card">
              <img src={company1} alt="footer_logo" className="img-fluid" />
            </div>
            <div className="company_card">
              <img src={company3} alt="footer_logo" className="img-fluid" />
            </div>
            <div className="company_card">
              <img src={company2} alt="footer_logo" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preblog;

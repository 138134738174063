import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Cards from "../components/Cards";
// import Pricing from "../components/Pricing";
import Contact from "../components/Contact";
import Aftercontact from "../components/Aftercontact";
import Preblog from "../components/Preblog";
import Blog from "../components/Blog";
import { Link } from "react-router-dom";
import BackToTopButton from "./BackToTopButton";
import "../style/home.css";
import WhatsAppLink from "./WhatsAppLink";

const Home = () => {
  return (
    <div className="body-main">
      <BackToTopButton />
      <WhatsAppLink />
      <Hero />
      <div>
        <About />
      </div>

      <div className="headline_services" id="services">
        Our Services
      </div>

      <div className="ag-format-container">
        <div className="ag-courses_box">
          <Cards
            text1="Website Design & Development"
            text2="Flerid crafts digital excellence, specializing in Website Design & Development to enhance your brand's online impact."
            text3=" Innovators in Website Design & Development, transforming concepts into compelling online experiences with precision and flair."
            style1={{
              backgroundColor: "#2272FF",
            }}
            // style3={{color:"black"}}
          />
          <Cards
            text1="Mobile App Development"
            text2="Flerid excels in Mobile App Development, delivering innovative solutions that redefine user experiences and exceed expectations."
            text3="Crafting cutting-edge mobile apps, Flerid specializes in seamless UI/UX, robust functionality, and client satisfaction. Elevate your business with us!"
            style1={{
              backgroundColor: "#2272FF",
            }}
          />
          <Cards
            text1="Digital Marketing Solutions"
            text2="Flerid excels in Social Media and Marketing, delivering top-notch services that elevate your brand's online presence."
            text3="We specialize in strategic campaigns, engaging content, and analytics, ensuring Flerid propels your brand to success."
            style1={{
              backgroundColor: "#2272FF",
            }}
          />

          <Cards
            text1="Social Media Marketing"
            text2="Flerid excels in Search Engine Optimization, enhancing online visibility and driving traffic for optimal business growth."
            text3="Flerid optimizes websites, boosts rankings, and maximizes online presence through expert Search Engine Optimization strategies and services."
            style1={{
              backgroundColor: "#2272FF",
            }}
          />

          
        </div>
      </div>

      {/* <div className="headline">Plans</div>
      <div className="price-cntr">
        <Pricing head="Basic" price="Rs.5000" />
        <Pricing head="Standard" price="Rs.7000" />
        <Pricing head="Premium" price="Rs.10000" />
      </div> */}
      <Contact />

      <Aftercontact />

      <Preblog />

      <section className="cards-wrapper">
        <div className="headline">Our Latest Blogs</div>
        <div className="card-grid-space">
          <Link to="/blog1">
            <Blog
              blog_number="01"
              date="6 April 2023"
              headline="Web Development"
              content="Why Web Development and Digital Marketing are Important for a Business ?"
              button_text="Website Development"
            />
          </Link>
          <Link to="/blog2">
            <Blog
              blog_number="02"
              date="6 Oct 2023"
              headline="Personal Branding"
              content="Why Fame and Social Media Popularity Matter in Business?"
              button_text="Digital Marketing"
            />
          </Link>
          {/* <Link to="/blog3">
            <Blog
              blog_number="03"
              date="6 Oct 2017"
              headline="HTML Syntax"
              content="The syntax of a language is how it works. How to actually write it. Learn HTML syntax…"
              button_text="HTML"
            />
          </Link> */}
        </div>
      </section>
    </div>
  );
};

export default Home;

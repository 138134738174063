import React from "react";
import styles from "../style/contact.module.css";
import Contact_form from "../components/Contact_form";
import ContactImg from "../images/24x7.png";
const Contact = () => {
  return (
    <div className={styles.contact_main}>
      <div className="container">
      <h1 className="title text-center mb-4" style={{ color: "white", fontSize:'4rem', fontWeight:'600' }}>
          Talk to Us
        </h1>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 mb-4">
            <div className={styles.contact_img}>
              <img src={ContactImg} />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6">
            <Contact_form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react'
import '../style/contact-page.css';
// import '../style/icons.css';
import Contact_form from '../components/Contact_form';
import WhatsAppLink from './WhatsAppLink';
import BackToTopButton from './BackToTopButton';
const Contact_page = () => {
  return (
    <div>
      <WhatsAppLink/>
      <BackToTopButton/>
      <section id="contact">
        <div className="contact-box">
          <div className="contact-links">
            <h2>CONTACT</h2>
            <p>Have questions or suggestions? We're here to assist you! Reach out to our dedicated team through the contact form below or connect with us via phone or email. Your feedback matters, and we're committed to providing prompt and helpful responses. Whether you're seeking information, partnership opportunities, or assistance, our contact page is your gateway to effective communication. We look forward to hearing from you and serving your needs with utmost priority and care.</p>
          </div>
          <div className="contact-form-wrapper">
          <h1 className="title text-center mb-4" style={{ color: "white", fontSize:'3rem', fontWeight:'600' }}>
          Talk to Us
        </h1>
            <Contact_form/>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact_page
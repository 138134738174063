import React from "react";
import "../style/icons.css";
import { Link } from "react-router-dom";
const Icons = () => {
  const phoneNumber = "+916003351943"; // Replace with the actual phone number
  const message = "Hello, Chat with us!"; // Replace with your desired message

  // Encode the phone number and message for use in the URL
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const encodedMessage = encodeURIComponent(message);

  // Create the WhatsApp link
  const whatsappLink = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;
  return (
    <div>
      <div className="icons">
        <div className="icon">
          <Link to="https://www.facebook.com/flerid04">
            <i className="fa fa-facebook"></i>
          </Link>
        </div>
        <div className="icon">
          <Link to="https://www.instagram.com/flerid_technologies/">
            <i className="fa fa-instagram"></i>
          </Link>
        </div>
        <div className="icon">
          <Link to="https://www.linkedin.com/company/web-cloud/?lipi=urn%3Ali%3Apage%3Asearch_results_generic_index%3Bcf9d7447-641f-4eb0-bf92-0339198c6421">
            <i className="fa fa-linkedin"></i>
          </Link>
        </div>
        <div className="icon">
          <Link to="https://t.me/+qPboi1o6pT1jOGY9">
            <i className="fa fa-telegram"></i>
          </Link>
        </div>
        <div className="icon">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div className="icon">
          <a href="https://www.youtube.com/@FleridTechnology">
            <i className="fa fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Icons;

import './App.css';
import Home from './components/Home';
import Navbar from './components/Menu';
import Services from './components/Services';
import Services1 from './components/Services1';
import Services2 from './components/Services2';
import Services3 from './components/Services3';
import Services4 from './components/Services4';
import Contact_page from './components/Contact_page';
import ABOUT_PAGE from './components/aboutpages/ABOUT_PAGE'
import Pricing_page from './components/Pricing_page';
import Portfolio from './components/aboutpages/Portfolio';
import Blog1 from './components/Blog1';
import Blog2 from './components/Blog2';
import Blog3 from './components/Blog3';
import Footer from './components/Footer';
import { BrowserRouter as Router,
Routes,
Route, useLocation} from 'react-router-dom';
import { useEffect } from 'react';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <>
     <Router>
      <Navbar/>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path="/home" element={<Home/>} />
          <Route path="/about" element={<ABOUT_PAGE/>} />
          <Route path="/contact" element={<Contact_page/>} />
          <Route path="/websiteDesign" element={<Services/>} />
          <Route path="/social_media_marketing" element={<Services1/>} />
          <Route path="/seo" element={<Services2/>} />
          <Route path="/mobile_app" element={<Services3/>} />
          <Route path="/soft_dev" element={<Services4/>} />
          {/* <Route path="/portfolio" element={<Portfolio/>} /> */}
          <Route path="/pricing" element={<Pricing_page/>} />
          <Route path="/blog1" element={<Blog1/>} />
          <Route path="/blog2" element={<Blog2/>} />
          <Route path="/blog3" element={<Blog3/>} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;

import React from "react";
import ServiceCard from "./ServiceCard";
import styles from "./Services.module.css";
import Image from "../images/software development 1.jpg";
import Graph from "./img/graph.png";
import Asthetic from "./img/asthetic.png";
import Faster from "./img/faster.png";
import Content from "./img/content.png";
import Optimized from "./img/optimized.png";
import Responsive from "./img/responsive.png";
import NeedWebsite from "./img/10276612_4421959.svg";
import BackToTopButton from "./BackToTopButton";
import WhatsAppLink from "./WhatsAppLink";

const Services4 = () => {
  return (
    <>
      <div className={styles.container}>
        <BackToTopButton />
        <WhatsAppLink/>
        <h1>Software Development</h1>
        <div className={styles.design}>
          <div className={styles.leftDesign}>
            Software development is the systematic process of designing, coding,
            testing, and maintaining computer programs to meet specific
            objectives. It involves creating applications or systems that enable
            various functionalities, from simple tasks to complex operations.
            Developers use programming languages and frameworks to translate
            ideas into functional software, ensuring it meets user requirements.
            Iterative cycles of development and testing refine the software,
            addressing bugs and enhancing features. This dynamic field
            encompasses a range of methodologies and practices to deliver
            efficient, reliable, and user-friendly software solutions.
          </div>
          <div className={styles.rightDesign}>
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.banner}>
        <h1>EMPOWERING YOUR VISION THROUGH SOFTWARE EXCELLENCE!</h1>
        <div className={styles.Cards}>
          <ServiceCard icon={Graph} heading={"Understanding of Your Business Needs"} />
          <ServiceCard
            icon={Asthetic}
            heading={"Robust Quality Management System"}
          />
          <ServiceCard icon={Content} heading={"Innovation & experience"} />
          <ServiceCard icon={Responsive} heading={"Results-Oriented Approach"} />
          <ServiceCard
            icon={Optimized}
            heading={"Strong Technical Expertise"}
          />
          <ServiceCard icon={Faster} heading={"Security Competency"} />
        </div>
      </div>

      <div className={styles.designSecond}>
        <div className={styles.leftDesign}>
          <h2>WHY DOES YOU NEED SOFTWARE DEVELOPMENT?</h2>
          Software development is essential for creating and maintaining digital
          solutions that power modern life. From business applications to mobile
          apps, software development drives innovation, enhances efficiency, and
          enables technology to meet evolving needs. It plays a pivotal role in
          shaping the way we work, communicate, and interact in today's digital
          world.
        </div>
        <div className={styles.rightDesign}>
          <img src={NeedWebsite} alt="" />
        </div>
      </div>
      <div className={styles.chooseUs}>
        <h1>"Let's Craft Your Digital Future Together!"</h1>
      </div>
    </>
  );
};

export default Services4;

// Neon blue (#2272FF) black (#000)

import React from "react";
import ServiceCard from "./ServiceCard";
import styles from "./Services.module.css";
import Image from "../images/SEO_1.jpg";
import Graph from "./img/graph.png";
import Asthetic from "./img/asthetic.png";
import Faster from "./img/faster.png";
import Content from "./img/content.png";
import Optimized from "./img/optimized.png";
import Responsive from "./img/responsive.png";
import NeedWebsite from "../images/SEO_2.jpg";
import BackToTopButton from "./BackToTopButton";
import WhatsAppLink from "./WhatsAppLink";

const Services2 = () => {
  return (
    <>
      <div className={styles.container}>
        <BackToTopButton/>
        <WhatsAppLink/>
        <h1>Search Engine Optimization</h1>
        <div className={styles.design}>
          <div className={styles.leftDesign}>
          Search Engine Optimization (SEO) is the strategic process of enhancing a website's visibility on search engines like Google. It involves optimizing content, improving site structure, and utilizing relevant keywords to boost rankings. The goal is to increase organic traffic, making the website more accessible and appealing to search engine algorithms. Effective SEO enhances online presence, driving potential customers to the site.
          </div>
          <div className={styles.rightDesign}>
            <img src={Image} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.banner}>
        <h1>ELEVATE YOUR ONLINE PRESENCE WITH SEO EXCELLENCE!</h1>
        <div className={styles.Cards}>
          <ServiceCard icon={Graph} heading={"Research"} />
          <ServiceCard
            icon={Asthetic}
            heading={"Reporting & Goal Setting"}
          />
          <ServiceCard icon={Content} heading={"Content Building"} />
          <ServiceCard icon={Responsive} heading={"Page Optimization"} />
          <ServiceCard
            icon={Optimized}
            heading={"Social & Link Building"}
          />
          <ServiceCard icon={Faster} heading={"Follow Up Reporting & Analysis"} />
        </div>
      </div>

      <div className={styles.designSecond}>
        <div className={styles.leftDesign}>
          <h2>WHY DO YOU NEED SEO?</h2>SEO is essential for online visibility and business success. It improves website ranking on search engines, enhances user experience, and boosts organic traffic. In a competitive digital landscape, effective SEO strategies are crucial for attracting and retaining a target audience.
        </div>
        <div className={styles.rightDesign}>
          <img src={NeedWebsite} alt="" />
        </div>
      </div>
      <div className={styles.chooseUs}>
        <h1>"Let's enhance your online presence with SEO!"</h1>
      </div>
    </>
  );
};

export default Services2;

// Neon blue (#2272FF) black (#000)

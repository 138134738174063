import React from "react";
import "../style/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="foot_details">
      <footer
        className="text-center text-lg-start text-white"
        style={{
          backgroundColor: "#1c2331",
          width: "100%",
          position: "relative",
          bottom: 0,
        }}
      >
        {/* Social Media Section */}
        <section
          className="d-flex justify-content-between p-4"
          style={{ backgroundColor: "#2272ff" }}
        >
          {/* Left */}
          <div className="me-5">
            <span>Get connected with us on social media:</span>
          </div>
          {/* Right */}
          <div>
            <a
              href="https://www.facebook.com/flerid04"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/FleridTech"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="mailto:fleridtechnology@gmail.com"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-envelope"></i>
            </a>
            <a
              href="https://www.instagram.com/flerid_technologies/"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/web-cloud/?lipi=urn%3Ali%3Apage%3Asearch_results_generic_index%3Bcf9d7447-641f-4eb0-bf92-0339198c6421"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/Joyin2"
              className="text-white me-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-github"></i>
            </a>
          </div>
        </section>
        {/* Links Section */}
        <section className="">
          <div className=" text-center text-md-start mt-5">
            <div className="row mt-3">
              {/* Flerid Technologies */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Flerid Technologies</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#2272ff",
                    height: "2px",
                  }}
                />
                <p>
                  Innovative solutions, seamless experiences, and cutting-edge
                  technology. Transforming businesses through digital
                  excellence. Your success, our mission.
                </p>
              </div>
              {/* Useful Links */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Useful Links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#2272ff",
                    height: "2px",
                  }}
                />
                <p>
                  <Link to="/home" className="text-white">
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/about" className="text-white">
                    About
                  </Link>
                </p>
                <p>
                  <Link to="/contact" className="text-white">
                    Contact
                  </Link>
                </p>
                <p>
                  <Link to="/pricing" className="text-white">
                    Pricing
                  </Link>
                </p>
              </div>
              {/* Services */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Services</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#2272ff",
                    height: "2px",
                  }}
                />
                <p>
                  <Link to="/websiteDesign" className="text-white">
                    Web Design & Development
                  </Link>
                </p>
                <p>
                  <Link to="/social_media_marketing" className="text-white">
                    Social Media Marketing
                  </Link>
                </p>
                <p>
                  <Link to="/seo" className="text-white">
                    SEO
                  </Link>
                </p>
                <p>
                  <Link to="/mobile_app" className="text-white">
                    Mobile App Development
                  </Link>
                </p>
                <p>
                  <Link to="/soft_dev" className="text-white">
                    Software Development
                  </Link>
                </p>
                {/* Add other service links as needed */}
              </div>
              {/* Contact */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#2272ff",
                    height: "2px",
                  }}
                />
                <p>
                  <i className="fa fa-home mr-3"></i>Silchar, Assam, India
                </p>
                <p>
                  <i className="fa fa-envelope mr-3"></i>
                  <a href="mailto:fleridtechnology@gmail.com">
                    fleridtechnology@gmail.com
                  </a>
                </p>
                <p>
                  <i className="fa fa-phone mr-3"></i>
                  <a href="tel:+916003351943">+91 6003351943</a>
                </p>
                <p>
                  <i className="fa fa-phone mr-3"></i>
                  <a href="tel:+919365414327">+ 91 9365414327</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Copyright */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        >
          &copy; 2024 Copyright:
          <a className="text-white" href="https://flerid.com/" target="_blank">
            Flerid Technology
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

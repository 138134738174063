// BackToTopButton.js

import React, { useState, useEffect } from 'react';
import '../style/BackToTopButton.css'; // Import your CSS file

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add scroll event listener to check if the button should be visible
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={`backToTopBtn ${isVisible ? 'visible' : ''}`}
      onClick={goToTop}
      title="Go to top"
    >
      &#8593; Top
    </button>
  );
};

export default BackToTopButton;
